/* Styles */
html,body {
  height: 100%;
  min-height: 100%;
  margin:0;
}

main {
  height: 100%;
  min-height: 100%;
}

#root {
  width: 100%;
  height:100%;
  min-height:100%;/*for mozilla*/
}

.content {
  width: 100%;
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 600px) {
  .content {
    width: 600px;
    margin: auto;
  }
}


.splash {
  background: #0259a0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.splash-background {
  background: #0259a0;
}

.splash-content {
  flex-grow: 1;
}


.splash-illustration-1 {
  position: absolute;
  top: 20px;
  left: 0;
}

.splash-illustration-2 {
  position: absolute;
  top: 50px;
  right: 0;
  text-align: right;
}

.splash-illustration-3 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.splash-illustration-4 {
  position: absolute;
  bottom: 0;
  right: 20px;
  text-align: right;
}

.splash-illustration-image-1 {
  width: 110px;
}
.splash-illustration-image-2 {
  width: 180px;
}
.splash-illustration-image-3 {
  width: 200px;
}
.splash-illustration-image-4 {
  width: 120px;
}

@media only screen and (min-width: 600px) {
  .splash-illustration-image-1 {
    width: 60%;
    height: 60%;
  }
  .splash-illustration-image-2 {
    width: 60%;
    height: 60%;
  }
  .splash-illustration-image-3 {
    width: 80%;
    height: 80%;
  }
  .splash-illustration-image-4 {
    width: 60%;
    height: 60%;
  }
}

.splash-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 100%;
}

.splash-title {
  z-index: 1;
  text-align: center;
}

.splash-title-img {
  width: 80%;
  height: 80%;
}

.main {
  background-color: #2eb0ae;
}

.splash-title {
  font-family: "Riffic Free Bold";
  color: #FFFFFF;
}

.home {
  width: 100%;
  min-height: 100%;
  text-align: center;
  font-family: "Riffic Free Bold";
  color: #235793;
  background-clip: content-box;
}

.home-background {
  background: #2eb0ae;
}

.home-white {
  background: #FFFFFF;
}

.container {
  display: flex;
  flex-direction: column;
}

.box {
  margin: 20px;
  padding: 10px;
  font-family: 'Somatic', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 10px;
}

.box-primary {
  background-color: #235793;
  box-shadow: 5px 5px #23579355;
  color: white;
}

.box-secondary {
  background-color: #235793;
  box-shadow: 5px 5px #23579355;
  color: white;
}

/*.box-secondary {
  background-color: #dc004e;
  box-shadow: 5px 5px #dc004e55;
  color: white;
}*/

.box-title {
  font-family: "Brady Bunch";
  font-size: 34px;
}

@media only screen and (max-width: 330px) {
  .box-title {
    font-size: 26px;
  }
}

.box-back {
  position: absolute;
  left: 20px;
  top: 20px;
}

.home-header {
  letter-spacing: 1px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: "Brady Bunch";
  color: white;
  font-size: 24px;
}

.home-text {
  font-family: "Riffic Free Bold";
  color: #235793;
  font-size: 16px;
}

.bear-input {
  background-color: white;
}

.bear-input-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bear-card {
  color: white;
  text-align: center;
}

.bear-title {
  font-family: "Brady Bunch";
  font-size: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bear-subtitle {
  text-align: center;
  font-size: 14px;
  margin-bottom: 4px;
}

.bear-subtitle-item {
}

.print-container {
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.print-content {
  position: absolute;
  top: 0px;
  left: 0px;
}

.print-center {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: 0px;
  width: 5px;
}

.print-image {
  position: absolute;
  top: -500px;
  left: -75px;
  z-index: 1;
}

.bear-collection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (min-width: 600px) {
  .bear-collection-item {
    flex-basis: 50%;
  }
}

.paw-text {
  font-size: 40px;
  line-height: 50%;
  font-family: "Brady Bunch";
  margin-right: 10px;
  color: #4a72ad;
}

.credits {
  padding-top: 15px;
  padding-bottom: 20px;
  font-family: "Roboto";
  font-size: 14px;
  text-align: center;
  color: white;
}

.credits a {
  color: white;
}

.map-header {
  position: fixed;
  width: 100%;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

.map-header-back {
  position: absolute;
  left: 0;
  top: 0;
}

.map-header-refresh {
  position: absolute;
  right: 0;
  top: 0;
}

.map-header-text {
  text-align: right;
  font-family: "Brady Bunch";
  color: #dc004e;
  font-size: 32px;
  margin-top: 10px;
  margin-right: 20px;
}

@font-face {
  font-family: 'Brady Bunch';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/BradBunR.woff'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Somatic';
  src: url('./fonts/Somatic-Rounded.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Riffic Free Bold';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/RifficFree-Bold.ttf'); /* IE9 Compat Modes */
  src: url('fonts/RifficFree-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');